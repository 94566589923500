<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-toolbar
        flat
      >
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          full-width
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :max="maxDate"
            :allowed-dates="allowedMonths"
            min="2020-01"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-spacer />
        <v-text-field
          v-model="searchNews"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
        <v-dialog
          v-model="deleteNewsDialog"
          max-width="800px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Delete News</span>
            </v-card-title>
            <v-alert
              :value="true"
              type="warning"
            >
              This action is irreversible!
              Are you sure you want to delete dealer news <strong>{{ editedItem.title }}</strong>?
            </v-alert>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="red darken-1"
                flat
                @click="deleteDealerNews"
              >
                Delete News
              </v-btn>
              <v-btn
                color="blue darken-1"
                flat
                @click="closeAllDialogs(false)"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialog"
          max-width="1000px"
        >
          <template #activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="ml-4 mb-2"
              v-on="on"
            >
              Add News
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-form
                ref="newsForm"
                v-model="validNews"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editedItem.title"
                        label="Title"
                        :rules="[val => !!val || 'Title required!']"
                        validate-on-blur
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-select
                        v-model="defaultSelect"
                        :items="items"
                        item-value="value.bar"
                        item-text="text"
                        label="Publish"
                        dense
                      />
                    </v-flex>
                    <v-flex xs12>
                      Description
                    </v-flex>
                    <v-flex xs12>
                      <div id="editor">
                        <ckeditor
                          :editor="editor"
                          v-model="editedItem.description"
                          :config="editorConfig"
                        />
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>

            <v-alert
              v-model="
                alert"
              type="error"
            >
              {{ alertMessage }}
            </v-alert>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                flat
                @click="closeAllDialogs(false)"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                flat
                @click="saveNews"
              >
                {{ formTitle }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="feeds"
        :loading="loading"
        :search="searchNews"
        class="elevation-1"
        disable-initial-sort
      >
        <template #items="props">
          <td class="text-xs-left">
            {{ props.item.date }}
          </td>
          <td class="text-xs-left">
            {{ props.item.title }}
          </td>
          <td class="text-xs-center">
            {{ props.item.publish }} / {{ props.item.pin }}
          </td>
          <td class="justify-center layout px-0">
            <v-icon
              small
              class="mr-2"
              color="blue"
              @click="updateNews(props.item)"
            >
              mdi-pencil-outline
            </v-icon>
            <v-icon
              small
              color="red lighten-2"
              @click="deleteNews(props.item)"
            >
              mdi-trash-can-outline
            </v-icon>
            &nbsp;
            <v-icon
              small
              color="yellow lighten-2"
              @click="pinNews(props.item)"
            >
              mdi-pin-outline
            </v-icon>
            &nbsp;
            <v-icon
              small
              color="orange lighten-2"
              @click="unpinNews(props.item)"
            >
              mdi-pin-off-outline
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="snackbar"
        color="success"
        :timeout="5000"
      >
        {{ snackbarText }}
        <v-btn
          dark
          flat
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
      <v-snackbar
        v-model="snackbarError"
        color="error"
        :timeout="5000"
      >
        {{ snackbarText }}
        <v-btn
          dark
          flat
          @click="snackbarError = false"
        >
          Close
        </v-btn>
      </v-snackbar>
    </panel>
  </v-scale-transition>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams, createPostParams } from '@/rest'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

const pageTitle = 'Dealers News'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/WidePanel'),
    ckeditor: CKEditor.component,
  },
  data: () => ({
    editor: Editor,
    menu: false,
    menus: false,
    maxDate: '',
    monthOf: null,
    month: null,
    title: pageTitle,
    alert: false,
    snackbar: false,
    snackbarError: false,
    snackbarText: null,
    deleteNewsDialog: false,
    alertMessage: null,
    dialog: false,
    validNews: true,
    pin: true,
    searchNews: null,
    loading: false,
    editorConfig: {
      toolbar: {
        items: ['heading', '|',
          'fontfamily', 'fontsize', '|',
          'alignment', '|',
          'fontColor', 'fontBackgroundColor', '|',
          'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
          'outdent', 'indent', '|',
          'bulletedList', 'numberedList', 'todoList', '|',
          'insertTable', '|',
          'insertImage', '|',
          'undo', 'redo'],
      },
    },
    breadcrumbs: [
      {
        text: 'DMT Kits', disabled: false, to: '/support/dmt_kits',
      },
      {
        text: pageTitle, disabled: true,
      },
    ],
    headers: [
      {
        text: 'Date',
        align: 'left',
        sortable: true,
        value: 'date',
      },
      {
        text: 'Title',
        align: 'left',
        sortable: true,
        value: 'title',
      },
      {
        text: 'Status',
        align: 'center',
        sortable: true,
        value: 'publish',
      },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        value: 'id',
      },
    ],
    defaultSelect: { text: 'Yes', value: { foo: 'Publish', bar: 1 } },
    items: [
      { text: 'Yes', value: { foo: 'Publish', bar: 1 } },
      { text: 'No', value: { foo: 'Unpublish', bar: 0 } },
    ],
    feeds: [],
    editedIndex: -1,
    editedItem: {
      id: null,
      title: null,
      description: '',
      publish: null,
    },
    defaultItem: {
      id: null,
      title: null,
      description: '',
      publish: null,
    },
  }),

  created: function () {
    this.getDealerNews()
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Add News' : 'Update News'
    },
  },

  watch: {
    pin: function (val) {
      this.pinNews(val)
    },
    monthOf: function (val) {
      this.getDealerNews(val)
    },
    dialog (val) {
      if (val) {
        this.$refs.newsForm.resetValidation()
      } else {
        this.closeAllDialogs(false)
      }
    },

    deleteNewsDialog (val) {
      val || this.closeAllDialogs(false)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
    // this.maxDate = this.day = now.plus({ days: -1 }).toFormat('yyyy-LL-dd')
  },

  methods: {
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2020, 1, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getDealerNews () {
      const params = createGetParams({
        month: this.monthOf,
      })
      this.loading = true
      this.$rest.get('getDealerNews.php', params)
        .then((response) => {
          this.feeds = response.data.feed
          this.loading = false
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
    },

    updateNews (id) {
      this.editedIndex = this.feeds.indexOf(id)
      this.editedItem = Object.assign({}, id)
      this.dialog = true
    },

    async saveNews () {
      if (!this.$refs.newsForm.validate()) {
        return
      }

      try {
        this.editedItem.title = this.editedItem.title.toUpperCase()
        const params = createPostParams({
          action: this.editedIndex === -1 ? 'addNews' : 'updateNews',
          payload: {
            title: this.editedItem.title,
            id: this.editedItem.id,
            publish: this.defaultSelect,
            description: this.editedItem.description,
          },
        })
        await this.$rest.post('postAction.php', params)

        this.snackbarText = this.editedIndex === -1
          ? 'You have successfully added a dealer news.'
          : 'You have successfully updated a dealer news.'
        this.snackbar = true
        this.closeAllDialogs(true)
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    deleteNews (id) {
      this.editedIndex = this.feeds.indexOf(id)
      this.editedItem = Object.assign({}, id)
      this.deleteNewsDialog = true
    },
    async deleteDealerNews () {
      try {
        const params = createPostParams({
          action: 'deleteNews',
          payload: {
            id: this.editedItem.id,
          },
        })
        await this.$rest.post('postAction.php', params)

        this.snackbarText = 'You have successfully deleted a dealer news'
        this.snackbar = true
        this.closeAllDialogs(true)
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    async pinNews (id) {
      try {
        this.editedIndex = this.feeds.indexOf(id)
        this.editedItem = Object.assign({}, id)
        const params = createPostParams({
          action: 'pinNews',
          payload: {
            id: this.editedItem.id,
            pin: 'pin',
          },
        })
        await this.$rest.post('postAction.php', params)

        this.snackbarText = 'You have successfully pinned a news'
        this.snackbar = true
        location.reload()
      } catch (error) {
        this.$nextTick(() => {
          this.snackbarText = error.message + ': ' + error.response.data
          this.snackbarStatus = 'error'
          this.snackbarError = true
        })
      }
    },
    async unpinNews (id) {
      try {
        this.editedIndex = this.feeds.indexOf(id)
        this.editedItem = Object.assign({}, id)
        const params = createPostParams({
          action: 'pinNews',
          payload: {
            id: this.editedItem.id,
            pin: 'unpin',
          },
        })
        await this.$rest.post('postAction.php', params)
        this.snackbarText = 'You have successfully unpinned a news'
        this.snackbar = true
        location.reload()
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    closeAllDialogs (save) {
      this.dialog = false
      this.deleteNewsDialog = false
      this.alert = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        save && this.getDealerNews()
      }, 300)
    },

    showError (message) {
      this.alertMessage = message
      this.alert = true
    },
  },
}
</script>

<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .my-textarea { color: black }

  #editor {
  /* text-align: center; */
  color: black;
  background-color: #ffffff;
}
</style>
